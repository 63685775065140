import axios from 'src/helpers/axios';
import persistWritable from 'src/utils/persist-writable';
import { get } from 'svelte/store';
import Apis from './api';
import type { RegisterDto } from 'src/models/user';
import { failure, success } from 'src/utils/toast-custom';


export const accessToken = persistWritable('accessToken', '');
export const authStore = {
	get accessToken() {
		return get(accessToken);
	},
	set accessToken(value: string) {
		accessToken.set(value);
	},

	login: async (email: string, password: string) => {
		try {
			const result = await axios.post(Apis.LOGIN, { email, password });
			
			if (result?.data) accessToken.set(result.data.accessToken);
		} catch (error: any) {
			if(error.response.status !== 401) failure(error.response.data.error)
			accessToken.set('')
		}
	},


	selfChangePassword: async (oldPassword: string, newPassword: string, callback: any) => {
		try {
			const result = await axios.patch(Apis.CHANGE_PASSWORD, { oldPassword, newPassword });
			if (result.data.success) {
				success('Password changed!')
				callback && callback();
			}
			else
				failure('Failed!')
		} catch (error: any) {
			failure('Failed!')
		}
	},

	forgotPasswordRequest: async (email: string) => {
		try {
			const result = await axios.post(Apis.FORGOT_PASSWORD_REQUEST, { email });
			return result;
		} catch (error: any) {
			/*empty*/
		}
	},

	changePassword: async (token: string, newPassword: string) => {
		try {
			const result = await axios.put(Apis.FORGOT_PASSWORD_TOKEN(token), { newPassword });
			return result;
		} catch (error: any) {
			/*empty*/
		}
	},

	register: async (dto: RegisterDto) => {
		try {
			const result = await axios.post(Apis.AUTH_REGISTER, dto);

			return result.data;
		} catch (error: any) {
			/*empty*/
		}
	},

	
};
