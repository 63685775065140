const API = import.meta.env.PUBLIC_API_URL || 'https://api.elitelinksgolf.com';

const Apis = {
	//ADMIN-auth
	LOGIN: `${API}/admin-auth/login`,
	STATISTICS: `${API}/admin/statistics`,
	FORGOT_PASSWORD_REQUEST: `${API}/admin-auth/forgot-password/request`,
	FORGOT_PASSWORD_TOKEN: (token: string) => `${API}/admin-auth/forgot-password/${token}`,
	CHANGE_PASSWORD: `${API}/auth/password`,

	//ADMIN-user
	ADMIN_USERS: `${API}/admin-users`,
	ADMIN_USERS_BY_ID: (id: string) => `${API}/admin-users/${id}`,
	ADMIN_USERS_BY_ID_ORDERS: (id:string) => `${API}/admin-users/${id}/orders`,
	ADMIN_USERS_BY_ID_REVIEWS: (id:string) => `${API}/admin-users/${id}/reviews`,

	//AUTH
	AUTH_REGISTER: `${API}/auth/register`,

	//SUPPORTS
	SUPPORTS: `${API}/admin/supports`,

	//USER
	USERS_ME: `${API}/users/me`,

	//SETTINGS
	SYSTEM_SETTING: `${API}/admin/system-settings`,

	// APPLICATIONS
	APPLICATIONS: `${API}/admin/applications`,
	APPLICATION_APPROVE: (id:string) => `${API}/admin/applications/${id}/approve`,
	APPLICATION_DECLINE: (id:string) => `${API}/admin/applications/${id}/decline`,

	// GOLFCLUBS
	GOLFCLUBS: `${API}/admin/golf-clubs`,
	MEMBERS_GOLFCLUB: (id: string) => `${API}/admin/golf-clubs/${id}/members`,
	
	//LOCATIONS
	LOCATIONS: `${API}/admin/location`,
	GOLFCLUBS_LOCATION: (id: string) => `${API}/admin/location/${id}/golf-clubs`,


	
	//FAQS
	FAQS: `${API}/admin/faqs`,

	// LOGS
	LOGS: `${API}/admin/audit-logs`,

	// ORDERS
	ORDERS: `${API}/admin/orders`,

	// OFFERS,
	OFFERS: `${API}/admin/offers`,

	OFFERS_ID: (id:string) => `${API}/admin/offers/${id}`,

	// MEMBERS
	MEMBERS: `${API}/admin/members`,
};

export default Apis;
