import { toast } from '@zerodevx/svelte-toast'
import type { SvelteToastOptions } from '@zerodevx/svelte-toast/stores'

export const success = (msg: string | SvelteToastOptions) => toast.push(msg, {
  theme: {
    '--toastBackground': 'green',
    '--toastColor': 'white',
    '--toastBarBackground': 'olive'
  }
})
export const warning = (msg: string | SvelteToastOptions) => toast.push(msg, { theme: { 
  '--toastBackground': 'orange',
  '--toastColor': 'white',
  '--toastBarBackground': 'orange'
 } })

export const failure = (msg: string | SvelteToastOptions) => toast.push(msg, { theme: { 
  '--toastBackground': 'red',
  '--toastColor': 'white',
  '--toastBarBackground': 'red'
 } })