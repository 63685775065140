import Axios from 'axios';
import { accessToken } from 'src/stores/auth';
import { failure } from 'src/utils/toast-custom';

const axios = Axios.create();
let _accessToken = '';
axios.interceptors.request.use(
	function (config) {
accessToken?.subscribe((data) => (_accessToken = data));
		if (config.headers) {
			(config.headers as any).Authorization = `Bearer ${_accessToken}`;
		}
		return config;
	},
	function (error) {
		return Promise.reject(error);
	}
);

axios.interceptors.response.use(
	function (response) {
		return response;
	},
	function (error) {
		switch (error.response?.status) {
			case 403:
				failure(error.response?.message || 'Forbidden');
				break;
			case 404:
				failure('Not found');
				break;
			case 401:
				failure('Unauthorized');
				accessToken.set('');
	
				break;
			case 502:
				failure('Bad Gateway');
				break;
			case 504:
				failure('Gateway Time-out');
				break;
			case 500:
				break;
		}
		return Promise.reject(error);
	}
);

export default axios;
